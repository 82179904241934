import Spinner from "./components/customComponents/Spinner";
import * as backendModule from "../src/modules/backendModule";
import { Link, useNavigate } from 'react-router-dom';
import animateModule from ".//modules/animateModule";
import { animateBox } from "./modules/componentAnimation"
import axios from "axios";
import React from "react";
import "./index.scss";

const App = () => {

  const FormModal = (props) => {
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [success, setSuccess] = React.useState(false);
    const nameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const messageRef = React.useRef(null);

    const send = (e) => {
      let data = {
        Name: nameRef.current.value,
        EMail: emailRef.current.value,
        attachment: selectedFile,
        Message: messageRef.current.value
      }

      console.log(selectedFile);

      let fd = new FormData()
      Object.keys(data).forEach(elem => {
        fd.append(elem, data[elem])
      })

      axios({
        method: "POST",
        url: `${backendModule.backendURL}/email/jobApplication`,
        data: fd,
        ...backendModule.axiosConfig,
        headers: {
          ...backendModule.axiosConfig.headers,
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (res.data.status === 'ok') {
          if (res.data.status === 'ok') {
            setSuccess(true);
          } else {

          }

        }
      }).catch((e) => {

      })
    }

    return <div className="component__form">
      <div style={{ display: success ? 'none' : '' }} className="component__form__box">
        <img id="close" src="images/close.png" alt="" onClick={props.onClose} />
        <img id="logo" src="images/logomali.svg" alt="" />
        <strong>Please fill the required fields</strong>
        <p>Full name</p>
        <input ref={nameRef} type='text' placeholder="Your full name" />
        <p>Email address</p>
        <input ref={emailRef} type='text' placeholder="Your Email address" />
        <p>Attach resume</p>
        <div id="fileinput" type='text' onClick={(e) => {
          let input = e.target.parentNode.querySelector("input[type='file']");
          if (input) input.click();
        }}>{selectedFile === null ? "Attach resume" : selectedFile.name}</div>
        <input onChange={(e) => { setSelectedFile(e.target.files[0]) }} type='file' style={{ display: 'none' }} />
        <p>Message</p>
        <textarea ref={messageRef} placeholder="Message" />
        <button onClick={() => send()}>Submit application</button>
      </div>
      <div style={{ display: success ? '' : 'none', height: 'fit-content' }} className="component__form__box">
        <img id="close" src="images/close.png" alt="" onClick={props.onClose} />
        <img id="logo" src="images/tick.png" alt="" />
        <strong>Congratulations! </strong>
        <br></br>
        <p>Your application has been successfully submitted.</p>
        <br></br>
        <p>Thank you for choosing our company as a potential employer</p>
      </div>
    </div>
  }

  return <div className="container">
    <img id="logo" src="images/logo.svg" alt="" />
    <img style={{ display: window.innerWidth > 1201 ? 'none' : 'block' }} src="images/lady.png" alt="" />
    <div className="container__right">
      <h1>Job vacancy</h1>
      <p>Are you a confident and outgoing individual looking for a new opportunity? CupitCoast.com is currently seeking female candidates to join our dating service team as video streamers. The job requires several streaming sessions per week on our platform. Ideal candidates must be 21 years of age or older.</p>
      <div id="border">
        <div className="container__right__box">
          <div>
            <h3>up to 100.00 EUR per hour</h3>
            <p>
              Earn up to 100.00 EUR per hour of streaming with us
            </p>
          </div>
          <div>
            <h3>
              Minimum payout is 250 EUR
            </h3>
            <p>
              which can be paid to your bank account or via crypto wallet
            </p>
          </div>
        </div>
      </div>
      <br></br>
      <p>You can monitor your earnings and streaming schedule on your account on CupidCoast.com and make payments at your own convenience.</p>
      <br></br>
      <p>
        You will have your own streaming manager to assist you with any questions or uncertainties. The only requirements are that you have a mobile phone, laptop, and a stable internet connection. We will take care of the rest and set everything up remotely.
        If you're interested in joining our team, apply now at CupitCoast.com
      </p>
      <br></br>
      <span className="container__right__button" onClick={(e) => animateBox(e, <FormModal />)}>Apply now</span>
      <br></br>
    </div>
    <div className="container__footer">
      <div>
        <strong>BONUSES</strong>
        <p>Bonuses are available for well-performed streaming sessions.</p>
      </div>
      <div>
        <strong>FLEX-TIME</strong>
        <p>You can work up to 5 days a week, but the minimum requirement is 2 streaming sessions of 1 hour per week.</p>
      </div>
    </div>
  </div>
};

export default App;